import { AngularFireModule } from '@angular/fire/compat';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/auth.service';
import { EmailUnsubscribeComponent } from './email-unsubscribe/email-unsubscribe.component';
import { EmailSubscribeComponent } from './email-subscribe/email-subscribe.component';
import { EmailsComponent } from './emails/emails.component';
import { FormsModule } from '@angular/forms';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { DialogComponent } from './core/dialog/dialog.component';
import { ToastComponent } from './core/toast/toast.component';
import { LoggedInGuard } from './core/logged-in.guard';
import { IsUserGuard } from './core/is-user.guard';
import { EulaComponent } from './eula/eula.component';
import { SupportComponent } from './support/support.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    EmailUnsubscribeComponent,
    EmailSubscribeComponent,
    EmailsComponent,
    DialogComponent,
    ToastComponent,
    EulaComponent,
    SupportComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase, 'realmbreakwebsite'),
    BrowserModule,
    AppRoutingModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    FormsModule
  ],
  providers: [
    AuthService,
    LoggedInGuard,
    IsUserGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
