import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { isValidEmail } from '../core/global-classes';
import { ToastService } from '../core/toast.service';

@Component({
  selector: 'app-email-subscribe',
  templateUrl: './email-subscribe.component.html',
  styleUrls: ['./email-subscribe.component.scss']
})
export class EmailSubscribeComponent implements OnInit {
  email = '';
  error = '';
  message = '';

  opened = false;

  constructor(private db: AngularFireDatabase,
    private toastService: ToastService) { }

  ngOnInit(): void {
  }

  signUp() {
    if(this.email && isValidEmail(this.email)) {
      let e = this.email;
      // this.db.list('/email_list').push(this.email);
      this.db.list('/email_list').query.ref.transaction(list => {
        if(!list) {
          list = [];
        }
        if(!list.filter(x => x === e).length) {
          list.push(e);
        }
        return list;
      });
      this.toastService.toast('Subscribed!', 'success');
      this.error = '';
      this.message = '';
      this.email = '';
      this.opened = false;
    } else {
      this.error = 'Invalid Email';
    }
  }

  cancel() {
    this.opened = false;
    this.email = '';
    this.error = '';
    this.message = '';
  }

}
