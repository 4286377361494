
<div class="overlay"></div>
<div class="container">
  <div class="dialog">
    <button *ngIf="closeable" class="exit div-button" (click)="emitClose()">
      X
    </button>
    <div class="title">
      <ng-content select="[title]"></ng-content>
    </div>
    <div class="desc">
      <ng-content select="[desc]"></ng-content>
    </div>
    <div class="button-zone">
      <ng-content select="[buttons]"></ng-content>
    </div>
  </div>
</div>
