import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Colors } from './global-colors';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  
  private subject = new Subject<any>();
  
  constructor() {
  }

  toast(message: string, type, time?) {
    let color = Colors.secondaryColor;
    let icon = '../../../assets/Checkmark.svg';
    switch(type) {
      case 'success':
        color = Colors.secondaryColor;
        icon = '../../../assets/Checkmark.svg';
        break;
      case 'danger':
        color = Colors.primaryAccent;
        icon = '../../../assets/error_x.svg';
        break;
      case 'info':
        color = Colors.secondaryAccent;
        icon = '../../../assets/info_i.svg';
        break;
    }
    this.subject.next({ type: "toast",
      text: message,
      icon: icon,
      shadow: '-5px 0px 0px 0px ' + color + ', 1px 1px 0px 0px var(--grey)',
      color: color,
      time: time || 3000
    });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
