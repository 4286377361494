import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email = '';
  password = '';

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  login() {
    this.authService.doLogin({email: this.email, password: this.password}).then((res:any) => {
      if(res) {
        this.router.navigate(['/emails']);
      }
    });
  }

  ngOnInit(): void {
  }

}
