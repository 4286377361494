import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  
  @Input()
  closeable = true;

  @Output()
  close = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.setFocus();
  }

  setFocus() {
    if(document.getElementsByClassName('dialog')[0].getElementsByTagName('button')[0]) {
      document.getElementsByClassName('dialog')[0].getElementsByTagName('button')[0].focus();
    } else {
      setTimeout(this.setFocus.bind(this), 0);
    }
  }

  emitClose() {
    this.close.emit(true);
  }
}
