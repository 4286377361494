import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  sub;

  user: BehaviorSubject<Object> = new BehaviorSubject(null);
  currentUserId;
  auth;

  constructor(private afAuth: AngularFireAuth) {

    this.sub = this.afAuth.authState.subscribe(auth => {
      if (auth) {
        // console.log(auth);
        /// signed in
        this.currentUserId = auth.uid;
        this.auth = auth;
      } else {
        /// not signed in
        this.currentUserId = null;
        this.auth = null;
      }
      this.user.next(this.auth);
    });

  }


  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(value.email, value.password)
      .then(res => {
        this.afAuth.setPersistence('session');
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  doLogout() {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signOut()
      .then(res => {
        resolve(res);
      }, err => reject(err));
    });
  }

  sendResetPassword(email) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  ngOnDestroy() {
    if(this.sub) {
      this.sub.unsubscribe();
      this.sub = null;
    }
  }
}
