

<div>
    <button class="newsletter" (click)="opened = true;">Join Newsletter</button>
</div>


<app-dialog *ngIf="opened" (close)="cancel()">
    <div title class="color-primary">Subscribe to Email List</div>
    <div desc class="info">
        <div class="row">
            <div class="form-info">
                <label for="email">Email</label>
                <input id="email" [(ngModel)]="email" required
                type="text" attr.aria-label="Email" title="Email" tabindex="0"/>
            </div>
        </div>
  
      <div *ngIf='error' class="errorMessage">{{error}}</div>
      <div *ngIf='message' class="successMessage">{{message}}</div>
    </div>
    <button buttons (click)="signUp()">Subscribe</button>
    <button buttons (click)="cancel()" ariaLabel="Cancel">Cancel</button>
</app-dialog>