import { Component, OnInit } from '@angular/core';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  messages = [];
  id = 0;

  constructor(private toastService: ToastService) {
  }

  ngOnInit() {
    //this function waits for a message from toast service
    this.toastService.getMessage().subscribe(message => {
        if(message) {
          this.id++;
          message.id = this.id;
          this.messages.push(message);
          if(this.messages.length > 6) {
            this.messages.shift();
          }
          setTimeout(() => {
            this.messages = this.messages.map(x => {
              if(x.id === message.id) {
                x.expired = true;
              }
              return x;
            });
            setTimeout(() => {
              this.messages = this.messages.filter(x => x.id !== message.id);
            }, 500);
          }, message.time);
        }
    });
  }

}
