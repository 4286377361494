<div class="info">
    <div class="row">
        <div class="form-info">
            <label for="email">Email</label>
            <input id="email" [(ngModel)]="email" required
            type="text" attr.aria-label="Email" title="Email" tabindex="0"/>
        </div>
    </div>

  <div *ngIf='error' class="errorMessage">{{error}}</div>
  <div *ngIf='message' class="successMessage">{{message}}</div>

  <button buttons (click)="remove()">Unsubscribe</button>
</div>