import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss']
})
export class EmailsComponent implements OnInit {

  sub;
  subUser;
  user;

  constructor(private db: AngularFireDatabase,
    private router: Router,
    private authService: AuthService) { 
      this.subUser = this.authService.user.subscribe((data:any) => {
        if(data) {
          this.user = data;
        }
        if(this.user) {
          this.sub = this.db.list('/email_list').valueChanges();
        }
      });
    }

  ngOnInit(): void {
  }

  logout() {
    this.authService.doLogout();
    this.router.navigate(['/']);
  }

}
