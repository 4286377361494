
export const Colors = {
    primaryColor: '#332a7c',
    secondaryColor: '#55cba9',
    tertiaryColor: '#1f79c2',
    quaternaryColor: '#5cc8d6',
    primaryAccent: '#ff5b43',
    secondaryAccent: '#f4bc00',
    tertiaryAccent: '#ff8e22',
    quaternaryAccent: '#ffd0d2',
    text: '#0D1010',
    background: '#ebf1f7',
    lightgrey: '#f4f4f4',
    grey: '#ccc',
    darkergrey: '#4b4e52',
    green: '#22b573',

};

