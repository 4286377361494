import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsUserGuard } from './core/is-user.guard';
import { LoggedInGuard } from './core/logged-in.guard';
import { EmailUnsubscribeComponent } from './email-unsubscribe/email-unsubscribe.component';
import { EmailsComponent } from './emails/emails.component';
import { EulaComponent } from './eula/eula.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SupportComponent } from './support/support.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [LoggedInGuard], data: { title: 'Login | RealmBreak Games', desc: 'Login to RealmBreak Games' } },
  { path: 'emails', component: EmailsComponent, canActivate: [IsUserGuard], data: { title: 'Emails | RealmBreak Games', desc: 'Emailing list for RealmBreak Games' } },
  { path: 'unsubscribe', component: EmailUnsubscribeComponent, data: { title: 'Unsubscribe | RealmBreak Games', desc: 'Unsubscribe from emailing list from RealmBreak Games' } },
  { path: 'eula', component: EulaComponent, data: { title: 'EULA | RealmBreak Games', desc: 'EULA for RealmBreak Games' } },
  { path: 'support', component: SupportComponent, data: { title: 'Support | RealmBreak Games', desc: 'Support page for RealmBreak Games' } },
  { path: '', component: HomeComponent, data: {title: 'RealmBreak Games'} },
  { path: '**', component: HomeComponent, data: {title: 'RealmBreak Games'} }
  
];


@NgModule({
  imports: [ RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload',
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
