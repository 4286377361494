
<div class="home">

    <div class="hero">
        <div class="hero-wrapper">
            <div class="menu">
                <a href="https://discord.gg/x2aXAZQAhP" target="_blank">Discord</a>
                <!-- <a href="https://discord.gg/x2aXAZQAhP" target="_blank"><img src="../../assets/Discord.svg" height="40px" alt="Discord"></a> -->
            </div>
            <img src="../../assets/hero_image3.jpg">
            <img class="watermark" src="../../assets/RBWatermark.svg">
            <app-email-subscribe class="letter"></app-email-subscribe>
        </div>
    </div>
    <div class="section">
        <div class="left">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/dR4DGaioxug" 
                title="Fallen Worlds Trailer" frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
            </iframe>
        </div>
        <div class="right">
            <!-- <div class="middle"> -->
                <div class="title">
                    Fallen Worlds
                </div>
                <div class="desc">
                    Fallen worlds is a fast-paced Action-RPG Roguelite Hack & Slash. Play single-player or up to 4-player online co-op in procedurally generated maps. Customize your ability colors to wow your friends!
                </div>
                <div class="desc whitelink">
                    <a href="https://store.steampowered.com/app/1930350/Fallen_Worlds/" target="_blank">Fallen Worlds Steam Page</a>
                </div>
            <!-- </div> -->
        </div>
    </div>
    <div class="section">
        <div class="right">
            <div class="title">
                Total Enemies Defeated:
            </div>
            <div class="desc big-text">
                {{totalEnemies | number:'1.0':'en-US'}}
            </div>
        </div>
    </div>
    <div class="section">
        <div class="right">
            <div class="title">
                Stay connected on your preferred platforms
            </div>
            <div class="desc">
                <a href="https://www.facebook.com/Realmbreak-Games-110445964205082" target="_blank"><img src="../../assets/Facebook.svg" height="40px" alt="Facebook"></a>
                <a href="https://www.instagram.com/realmbreakgames/" target="_blank"><img src="../../assets/Instagram.svg" height="40px" alt="Instagram"></a>
                <a href="https://discord.gg/x2aXAZQAhP" target="_blank"><img src="../../assets/Discord.svg" height="40px" alt="Discord"></a>
            </div>
        </div>
    </div>
    <div class="section">
        <div class="right">
            <div class="title">
                <app-email-subscribe></app-email-subscribe>
            </div>
            <div class="desc">
            </div>
        </div>
    </div>
</div>
