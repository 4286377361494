import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private titleService: Title,
    private meta: Meta,
    private router: Router) {

    this.router.events
    .pipe(filter(event => event instanceof RoutesRecognized), 
    map( (event: RoutesRecognized) => {
      return event.state.root.firstChild.data;
    }))
    .subscribe(data => {
      this.titleService.setTitle(data['title'] || 'Realm Break Games');
      this.meta.updateTag({ name: 'description', content: data['desc'] || "Realm Break Games - Multiplayer games done right" });
    });
  
  }
}
