import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  sub;
  totalEnemies = 0;

  constructor(private db: AngularFireDatabase) {
    this.sub = this.db.list('/kill_list').valueChanges().subscribe(list => {
      this.totalEnemies = 0;
      list.forEach(record => {
        this.totalEnemies += record['kills'];
      })
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if(this.sub) {
      this.sub.unsubscribe();
      this.sub = null;
    }
  }

}
