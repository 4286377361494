<div>
    <div class="row">
        <div class="form-info">
            <label for="email">Email</label>
            <input id="email" [(ngModel)]="email" required
            type="text" attr.aria-label="Email" title="Email" tabindex="0"/>
        </div>
    </div>
    <div class="row">
        <div class="form-info">
            <label for="password">Password</label>
            <input id="password" [(ngModel)]="password" required
            type="password" attr.aria-label="Password" title="Password" tabindex="0"/>
        </div>
    </div>
    <div class="row">
        <div class="form-info">
            <button (click)="login()">Log In</button>
        </div>
    </div> 
</div>